import { Link } from "gatsby";
import React, { useState, useEffect } from "react";
import { Button, Form, Container, Row, Col, Fade } from "react-bootstrap";
import { connectStats, Pagination, connectPagination } from 'react-instantsearch-dom';
import $ from "jquery";
import ScrollAnimation from "react-animate-on-scroll";
import { navigate } from "@reach/router";
import Select from "react-dropdown-select";
import LoadableAbout from "../FilterBlock/FilterBlock";
import { PropertyCardList } from "./SearchItems";
import { PropertyCardListTablet } from "./SearchItemsTablet";
import { PropertyCardListTabletPro } from "./SearchItemsTabletPro";
import { PropertyCardListDesktop } from "./SearchItemsDesktop";
import PropertyCard from "../PropertyCard/NewHomesPropertyCard"
import Paginations from "../Paginations/Paginations"
import NoImage from "../../../images/property-no-image.png"
import { useWindowSize } from '../../../hooks/winodw-size'
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import parse from "html-react-parser";
import AlertEnquiry from "../SearchAlert";
import Enquiry from "../../Branches/NewHomesBookAViewing";
import { connectSortBy } from "react-instantsearch-core";

import "./SearchListNewHomes.scss";
import {
  isTablet,
  isIPad13,
  isIOS13,
  isMobile,
  isMobileOnly,
  isDesktop,
} from "react-device-detect";

const sortBybox = ({ currentRefinement, defaultRefinement, refine, value, items }) => {
  let sortBy = "";
  if (currentRefinement != null || defaultRefinement != undefined) {
    if (currentRefinement == process.env.GATSBY_ALGOLIA_NEW_DEVELOPMENT_INDEX_NAME) {
      sortBy = defaultRefinement;
    } else {
      sortBy = currentRefinement;
    }
  }
  return (
    <div className="filter-option result-search-recent-filter">
      <i className="icon-sort"></i>
      <Select
        options={items}
        placeholder="Sort"
        classNamePrefix={"select-opt filter-box"}
        className="filter-sortby"
        value={sortBy ? items.find(obj => obj.value == sortBy) || items.find(obj => obj.value == sortBy) : items.find(obj => obj.value == value)}
        searchable={false}
        onChange={event => {
          refine(event[0].value)
        }}
      />
    </div>
  )
}


const CustomSortBy = connectSortBy(sortBybox);
const CustomPagination = ({ currentRefinement, nbPages, refine, createURL, pagenumber }) => {

  useEffect(() => {
    if (parseInt(nbPages) <= parseInt(pagenumber)) {
      $('.page-next').attr('disabled', true);
    }
    else {
      $('.page-next').attr('disabled', false);
    }

    if (parseInt(pagenumber) == 1) {
      $('.page-back').attr('disabled', true);
    }
    else {
      $('.page-back').attr('disabled', false);
    }
  }, [pagenumber])


  // Pagination select box
  const paginationselect = () => {
    var page_val = document.getElementById('pagination-select').value
    navigate(createURL(page_val))
    refine(page_val);

    if (window) {
      window.scroll({
        top: -100,
        behavior: 'smooth'
      });
    }
  }
  // Pagination select box

  // Next Pagination Button
  const nextpage = () => {
    var next_page_number = parseInt(pagenumber) + 1
    navigate(createURL(next_page_number))
    refine(next_page_number);

    if (window) {
      window.scroll({
        top: -100,
        behavior: 'smooth'
      });
    }
  }
  // Next Pagination Button

  // Previous Pagination Button
  const prevpage = () => {
    var prev_page_number = parseInt(pagenumber) - 1
    navigate(createURL(prev_page_number))
    refine(prev_page_number);

    if (window) {
      window.scroll({
        top: -100,
        behavior: 'smooth'
      });
    }
  }
  // Previous Pagination Button

  return (
    <Row>
      <Col md={3} className="d-none d-md-block">
        <button className="page-back" onClick={() => prevpage()}><i className="page-arrow-back"></i> Back</button>
      </Col>
      <Col md={6} className="text-center">
        Page:<select id="pagination-select" onChange={() => paginationselect()}>
          {new Array(nbPages).fill(null).map((_, index) => {
            const page = index + 1;
            return (
              <option value={page}
                selected={pagenumber == page ? "selected" : ""}
              >
                {page}
              </option>
            );
          })}
        </select>of {nbPages}
      </Col>
      <Col md={3} className="d-none d-md-block">
        <button className="page-next" onClick={() => nextpage()}>Next <i className="page-arrow-next"></i></button>
      </Col>
    </Row>
  )
}

const CustomPaginationRender = connectPagination(CustomPagination);

const SearchList = (props) => {
  const [windowWidth] = useWindowSize();
  const [colour, setColour] = useState(false);
  const [mview, setView] = useState('');
  const [mapview, setMapView] = useState('');
  const [second, setSecond] = useState(false);
  const [info, setInfo] = useState(false);

  const handleSecond = () => {
    setSecond(!second);
  }

  const changeColour = () => {
    setColour(!colour);
  }

  const listViewMobile = (e) => {
    $(".list-view").removeClass("active")
    $(".view-pin").addClass("active")
  }

  const mapViewMobile = (e) => {
    $(".list-view").addClass("active")
    $(".view-pin").removeClass("active")
  }

  $(document).on('click', '.ais-Pagination-link', function () {
    if (window) {
      window.scroll({
        top: -100,
        behavior: 'smooth'
      });
    }
  });
  // console.log("xxx1",props.mapView)

  const pageurl = typeof window !== 'undefined' ? window.location.href : ''
  var blockCount = 0;

  //This code snippet for setting up description length based on window size
  var descLength = 400;
  if (windowWidth >= 1600 && windowWidth <= 1700) {
    descLength = 400
  } else if (windowWidth >= 1701 && windowWidth <= 1800) {
    descLength = 500
  }
  else if (windowWidth >= 1801 && windowWidth <= 1900) {
    descLength = 600
  }
  else if (windowWidth >= 1901 && windowWidth <= 2000) {
    descLength = 600
  }
  else if (windowWidth >= 2001) {
    descLength = 600
  }

  var res_page = parseInt(props.page)
  var res_page_one = res_page * 20
  var res_page_two = res_page_one - 19
  if (parseInt(props?.nbHits) < res_page_one) {
    res_page_one = props?.nbHits
  }
  useEffect(()=>{
    localStorage.removeItem('initialViewProperty');
  },[]);

  useEffect(() => {
    if (parseInt(props?.nbHits) < 5) {
      var target = $(".search-list-block")
      $("html,body").animate(
        { scrollTop: target.offset().top - 100 },
        10
      )
    }

  }, [props?.nbHits]);

  let myH1 = props.h1;
  if(props?.mapdragbyuser){
    const myH1Arr = myH1.split(" in ");
    myH1 = myH1Arr[0]+' in this area';
  }
  const shareurl = typeof window !== 'undefined' ? window.location.href : ''

  return (
    <React.Fragment>
      <Container id="res-div-one">
        {props.mapView ? '' :
          <React.Fragment>
            <Row className="property-view">
              <Col md="12">
              <h1 className="d-none">{`${props?.nbHits.toLocaleString()} ${myH1}`}</h1>
                <div className={`${props.mapView ? 'grid' : ''}`}>
                  <span className="search-number">{props?.nbHits.toLocaleString()} {props.promotion == "with buy to let opportunity" ? "Browse our selection of buy-to-let properties currently available on the market." : myH1}
                    <i className="icon-info" onClick={() => { setInfo(!info) }}></i>
                  </span>
                  {info &&
                    <div className="result-desc-content">
                      {props.promotion == "with buy to let opportunity" ? <p className="col-md-12 mb-0 mt-2">We show you what will offer you the highest possible yields to make things even easier for you.</p> : <p className="col-md-12 mb-2 mt-2"> {props.desc} <Link to="/get-in-touch/"><u>contact us</u></Link>.</p>}
                    </div>
                  }
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                {props?.nbHits > 0 &&
                  // <p className="showing-property-text">Showing {res_page_two}-{res_page_one} of {props?.nbHits.toLocaleString()} properties</p>
                  <p className="showing-property-text">Showing {res_page_two}-{props?.nbHits.toLocaleString()} of {props?.nbHits.toLocaleString()} properties</p>
                }
              </Col>
              <Col md="6">
                <div>
                  <div className="search-right">

                    {/* <button type="button" className="alert-btn">
                    <i className="icon-mail"></i>
                    {windowWidth <= 991 && <AlertEnquiry name="" class="mail"><span>Create alert</span></AlertEnquiry>}
                    {windowWidth > 991 && <AlertEnquiry name="" class="mail"><span>Create alert for this search</span></AlertEnquiry>}

                  </button> */}
                    {/* <button type="button" className="list-view">
                    <i className="icon-view"></i>
                    <span>List View</span>
                  </button>
                  <button type="button" className="view-pin">
                    <i className="icon-pin"></i>
                    <span>Map View</span>
                  </button> */}
                    {props.mapView ?
                      <button type="button" className="list-view" onClick={() => { props.changeToListUrl(); sessionStorage.setItem('MainView', 'List'); localStorage.removeItem('map-hits'); }}>
                        <i className="icon-view"></i>
                        <span>List View</span>
                      </button> :
                      <button type="button" className="view-pin" onClick={() => { props.changeToMapUrl(); sessionStorage.setItem('MainView', 'Grid') }}>
                        <i className="icon-pin"></i>
                        <span>Map View</span>
                      </button>
                    }

                    {/* {props.mapView ?
                  <button type="button" className="grid-view" onClick={() => { props.changeToListUrl(); sessionStorage.setItem('MainView', 'List') }}>
                    <i className="icon-view"></i>
                    <span>List View</span>
                  </button> :
                  <button type="button" className="grid-view" onClick={() => { props.changeToMapUrl(); sessionStorage.setItem('MainView', 'Grid') }}>
                    <i className="icon-grid"></i>
                    <span>Grid View</span>
                  </button>
                } */}
                    {/* {!props.mapView &&
                      <div className="recent-filter-block">
                        <CustomSortBy
                          defaultRefinement={props.sortByIndex}
                          items={sortItem}
                        />
                      </div>
                    } */}
                    {/* <button type="button" className="sort-list" >
                  <i className="icon-sort"></i>
                  <span>Most Recent</span>
                </button> */}


                  </div>
                </div>
              </Col>
            </Row>
          </React.Fragment>
        }

        <div className={`${props.mapView ? 'property-card-list lg-view d-none' : 'no-pad'}`}>
          <Row>
            {props?.hits.map((item, i) => {
              var url = '/new-home-for-sale/' + item.slug + '-' + item.objectID+'/'
              var image_url = ''
              var sub_image_url_one = ''
              var sub_image_url_two = ''
              var sub_image_url_three = ''
              console.log('images', item)

              if (item.images1) {
                image_url = item.images1.images_src[0];
                // var image_temp = image_url.substr(image_url.indexOf('i.dev'))
              }
              if (item.images2) {
                sub_image_url_one = item.images2.images_src[0];
                sub_image_url_two = item.images2.images_src[1];
                sub_image_url_three = item.images2.images_src[2];
              }

              // console.log("ggfx",item.imagetransforms)
              let processedImages = JSON.stringify({});
              if (item.imagetransforms?.images_Transforms) {
                processedImages = item.imagetransforms.images_Transforms
                // console.log("ccc1",JSON.parse(processedImages),item.objectID)
              }

              // console.log("ccc",item.imagetransforms,{[image_temp] : item.images[0]})

              return (
                <React.Fragment>
                  {props.mapView ?
                    <PropertyCard
                      image_url={image_url}
                      // BoxColor={item.BoxColor}
                      BedCount={item.bedroom}
                      BathCount={item.bathroom}
                      areaSup={item.reception}
                      PropertyPricePrefix={item.price_prefix}
                      min_price={item.min_price}
                      max_price={item.max_price}
                      PropertyPrice={item.price_range}
                      PropertyLine={item.development_title}
                      PropertyHeading={item.development_title}
                      PropertyText={item.short_description}
                      processedImages={processedImages}
                      objectID={item.objectID}
                      url={url}
                      type="new-homes"
                      showcased_property={item.showcased_property}
                      spotlight_property={item.spotlight_property}
                      virtual_tour={item.virtual_tour}
                      floorplan={item.floorplan}
                      imageCount={item.imageCount}
                    />
                    :
                    <Col lg="12" md="6" className="d-md-block property-card-wrapper" id={item.objectID}>
                      {item.showcased_property ?
                        <div className="top-bar showcased">
                          <span className="top-bar-text">Showcased Property</span>
                        </div> : item.spotlight_property ?
                          <div className="top-bar spotlighted">
                            <span className="top-bar-text">spotlighted Property</span>
                          </div> : ''}

                      <div className={`property-card property-card-big ${item.showcased_property ? 'property-card-top' : item.spotlight_property ? 'spotlighted property-card-top' : ''}`}>
                        <div className="properties-wrapper">
                          <Link 
                          to={`${url}`} 
                          onClick={() => {
                                   // Save item.objectID to local storage when Link is clicked
                                   console.log("Gerol",item)
                                   localStorage.setItem('initialViewProperty', item.objectID);
                               }}
                          className="img-link">
                            <picture>
                              {image_url ?
                                <img src={image_url} alt={`${item.development_title} - Douglas Allen`} /> :
                                <img src={NoImage} alt="img" />
                              }
                            </picture>

                            <div className="gallery-img d-lg-flex d-none">
                              {sub_image_url_one ?
                                <img src={sub_image_url_one} alt={`${item.development_title} - Douglas Allen`} />
                                :
                                <img src={NoImage} alt="img" />
                              }
                              {sub_image_url_two ?
                                <img src={sub_image_url_two} alt={`${item.development_title} - Douglas Allen`} /> :
                                <img src={NoImage} alt="img" />
                              }
                              {sub_image_url_three ?
                                <img src={sub_image_url_three} alt={`${item.development_title} - Douglas Allen`} /> :
                                <img src={NoImage} alt="img" />
                              }
                            </div>
                            <div className="btn-group-wrap">
                              <button className="btn-camera"><i className="icon-camera-gallery"></i> <span>1/{item.imageCount}</span></button>
                              {item.virtual_tour &&
                                <button className="btn-play-circle"><i className="icon-play-circle"></i></button>}
                              {item.floorplan?.url &&
                                <button className="btn-flor"><i className="icon-flor"></i> </button>
                              }
                            </div>
                          </Link>
                        </div>
                        <div className="about-properties">

                          <div className="properties-info">

                            <h2>
                              <Link 
                               to={`${url}`} 
                               onClick={() => {
                                   // Save item.objectID to local storage when Link is clicked
                                   localStorage.setItem('initialViewProperty', item.objectID);
                               }}
                              > {item.development_title}</Link>
                            </h2>
                            {
                              item.min_price && item.max_price &&
                              <React.Fragment>
                                {item.min_price == item.max_price ?
                                  <span className="property-price d-flex align-items-center">
                                    {item.price_prefix} £{item.min_price ? item.min_price.toLocaleString() : 0}
                                  </span> :
                                  <span className="property-price d-flex align-items-center">
                                    {item.price_prefix} £{item.min_price ? item.min_price.toLocaleString() : 0} - £{item.max_price ? item.max_price.toLocaleString() : 0}
                                  </span>
                                }
                              </React.Fragment>
                            }
                            <div className="properties-icons d-lg-flex d-none">
                              {item?.bedrooms ?
                                <span className="text">
                                  <i className="icon-bedroom"></i>
                                  <span className="sm-text">{item.bedrooms.toString()}</span>
                                </span> : ''
                              }
                            </div>
                            {item.long_description ?
                              <p className="d-lg-block d-none new-dev-content">{parse(item.long_description.substr(0, descLength))}...</p> :
                              <p className="d-lg-block d-none">{parse(item.short_description.substr(0, descLength))}...</p>}
                            <div className="bottom-block d-lg-block d-none">
                              {/* <Link to="#" className="number-text">
                        <i className="icon-phone"></i>
                        <span className="call-to">01227 479317</span>
                      </Link> */}

                              <a href="javascript:void(0);" className="mail-text">
                                <i className="icon-envelope"></i>
                                <Enquiry name="" property_img={image_url} crm_id={item.development_id} page_url={shareurl} property_title={item.development_title} property_id={item.development_id} address={item.address} class="mail"><span>Enquire</span></Enquiry>
                              </a>
                              {/* <Link to="#" className="save-list" onClick={handleSecond}>
                        <i className={second ? "icon-heart-fill" : "icon-heart"}>
                        </i>
                        <span>Save</span>
                      </Link> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>}
                </React.Fragment>
              )
            })}

            {props.mapView && (1 == 1) ? '' :

              <div className="justify-content-center main-pagination-container no-pad">
                {props?.nbHits > 20 &&
                  <>

                    <React.Fragment>
                      {/* <Col lg={12} className="d-flex justify-content-center py-3">
          <Pagination
            showFirst={true}
            showPrevious={true}
            showNext={true}
            showLast={true}
            // defaultRefinement={props.page}
            padding={2}
          />
        </Col> */}
                      <CustomPaginationRender pagenumber={props.page} />
                    </React.Fragment>
                  </>
                }
              </div>
            }
          </Row>
        </div>

      </Container>
    </React.Fragment>
  );
};

export default connectStats(SearchList);